.root {
  height: 8px;
  border-radius: 8px;
  background-color: white;
}
.bar1Determinate {
  background-color: #FAC377;
  border-radius: 8px;
}
.test {
 background-color: red;
}
