@font-face {
font-family: '__sfUIDisplayLight_834bb1';
src: url(/_next/static/media/b69a840106f3c819-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__sfUIDisplayLight_Fallback_834bb1';src: local("Arial");ascent-override: 100.35%;descent-override: 25.42%;line-gap-override: 0.00%;size-adjust: 94.88%
}.__className_834bb1 {font-family: '__sfUIDisplayLight_834bb1', '__sfUIDisplayLight_Fallback_834bb1'
}.__variable_834bb1 {--font-sf-ui-display-light: '__sfUIDisplayLight_834bb1', '__sfUIDisplayLight_Fallback_834bb1'
}

@font-face {
font-family: '__sfUIDisplayUltraLight_e0a3c9';
src: url(/_next/static/media/5e84782ace960712-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__sfUIDisplayUltraLight_Fallback_e0a3c9';src: local("Arial");ascent-override: 106.59%;descent-override: 27.00%;line-gap-override: 0.00%;size-adjust: 89.32%
}.__className_e0a3c9 {font-family: '__sfUIDisplayUltraLight_e0a3c9', '__sfUIDisplayUltraLight_Fallback_e0a3c9'
}

@font-face {
font-family: '__sfUIDisplayMedium_30b646';
src: url(/_next/static/media/16fd7735d8dc4951-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__sfUIDisplayMedium_Fallback_30b646';src: local("Arial");ascent-override: 94.89%;descent-override: 24.04%;line-gap-override: 0.00%;size-adjust: 100.34%
}.__className_30b646 {font-family: '__sfUIDisplayMedium_30b646', '__sfUIDisplayMedium_Fallback_30b646'
}

@font-face {
font-family: '__sfUIDisplaySemiBold_8696fd';
src: url(/_next/static/media/ea627b94b9a2ee9f-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__sfUIDisplaySemiBold_Fallback_8696fd';src: local("Arial");ascent-override: 92.96%;descent-override: 23.55%;line-gap-override: 0.00%;size-adjust: 102.43%
}.__className_8696fd {font-family: '__sfUIDisplaySemiBold_8696fd', '__sfUIDisplaySemiBold_Fallback_8696fd'
}

@font-face {
font-family: '__sfUIDisplayBold_f352b0';
src: url(/_next/static/media/fe6498e791ef2ada-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__sfUIDisplayBold_Fallback_f352b0';src: local("Arial");ascent-override: 90.56%;descent-override: 22.94%;line-gap-override: 0.00%;size-adjust: 105.14%
}.__className_f352b0 {font-family: '__sfUIDisplayBold_f352b0', '__sfUIDisplayBold_Fallback_f352b0'
}

.SearchBox_root__GcEmm {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: hidden;
}
.SearchBox_paper__lh_WV {
  display: flex;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.styles_root__5Pizk {
  height: 8px;
  border-radius: 8px;
  background-color: white;
}
.styles_bar1Determinate__qCq06 {
  background-color: #FAC377;
  border-radius: 8px;
}
.styles_test__GB_kb {
 background-color: red;
}

.UISideBar_SearchBox__MO6Wc {
 margin: 0px 10px 0px 10px;
}

/*Search Box Classes*/
.UISideBar_SearchBoxRoot__FtXyq {
 border: 1px solid #566680;
 border-radius: 8px;
 background-color: transparent;
 margin-bottom: 20px;
}
.UISideBar_SearchBoxSearchIcon__ilqXT {
 color: white
}
.UISideBar_SearchBoxInputBase__9Sd8o {
 color: white;
}
/*Search Box Classes*/
