.SearchBox {
 margin: 0px 10px 0px 10px;
}

/*Search Box Classes*/
.SearchBoxRoot {
 border: 1px solid #566680;
 border-radius: 8px;
 background-color: transparent;
 margin-bottom: 20px;
}
.SearchBoxSearchIcon {
 color: white
}
.SearchBoxInputBase {
 color: white;
}
/*Search Box Classes*/