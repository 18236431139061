.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: hidden;
}
.paper {
  display: flex;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
